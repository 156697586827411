import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';

const darkMode = {
  background: '#121212',
  color: '#fff',
};

const lightMode = {
  background: '#fff',
  color: '#121212',
};

// Keyframe for the jumping animation
const jump = keyframes`
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-100px);
  }
  100% {
    transform: translateY(0);
  }
`;

const AppContainer = styled.div`
  background-color: ${(props) => (props.darkMode ? darkMode.background : lightMode.background)};
  color: ${(props) => (props.darkMode ? darkMode.color : lightMode.color)};
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  overflow: hidden;
  flex-direction: column;
`;

const Banana = styled.div`
  font-size: 50px;
  animation: ${jump} 1s ease-in-out infinite;
`;

const Button = styled.button`
  background-color: ${(props) => (props.darkMode ? '#444' : '#ddd')};
  color: ${(props) => (props.darkMode ? '#fff' : '#121212')};
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  margin-top: 20px;
  border-radius: 5px;

  &:hover {
    background-color: ${(props) => (props.darkMode ? '#666' : '#bbb')};
  }
`;

function App() {
  const [darkMode, setDarkMode] = useState(true);

  const goToWebsite = () => {
    window.location.href = 'https://www.bananajump.com/interstice'; // Replace with your desired URL
  };

  return (
    <AppContainer darkMode={darkMode}>
      <Banana>🍌</Banana>
      <Button darkMode={darkMode} onClick={() => setDarkMode(!darkMode)}>
        Toggle Light Mode
      </Button>
      <Button darkMode={darkMode} onClick={goToWebsite}>
        Interstice
      </Button>
    </AppContainer>
  );
}

export default App;
